/*--------------------
    Service Styles  
----------------------*/
.service {
    &.service__style--1 {
        padding: 50px 35px;
        padding-bottom: 55px;
        transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media #{$laptop-device} {
            padding: 30px 28px;
        }

        @media #{$md-layout} {
            padding: 30px 25px;
        }

        @media #{$sm-layout} {
            padding: 30px 14px;
        }

        .icon {
            @extend %transition;
            font-size: 45px;
            font-weight: 400;
            margin-bottom: 23px;
            color: var(--color-primary);
            text-align: center;
            border-radius: 100%;
            display: flex;
            justify-content: center;

            @media #{$md-layout} {
                margin-bottom: 11px;
            }

            svg {
                stroke-width: 1 !important;
            }
        }

        .content {
            .title {
                margin-bottom: 19px;
                @extend %transition;

                @media #{$md-layout} {
                    margin-bottom: 8px;
                }

                a {
                    color: inherit;
                    @extend %transition;

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }

            p {
                @extend %transition;
            }
        }

        &:hover {
            transform: translateY(-5px);
        }

        &.rbt-border {
            border: 1px solid var(--color-border);
        }

        &.text-start {
            padding: 50px;

            @media #{$laptop-device} {
                padding: 40px 30px;
            }

            @media #{$lg-layout} {
                padding: 40px 30px;
            }

            @media #{$md-layout} {
                padding: 40px 30px;
            }

            @media #{$sm-layout} {
                padding: 30px 20px;
            }

            .icon {
                justify-content: left;
            }
        }

        &.icon-circle-style {
            background-color: transparent;
            box-shadow: none;
            padding: 0px 35px;
            margin-top: 25px;

            @media #{$md-layout} {
                padding: 0px 10px;
            }

            @media #{$sm-layout} {
                padding: 0px 10px;
            }

            .icon {
                color: var(--color-pink);
                display: flex;
                justify-content: center;
                width: 90px;
                height: 90px;
                margin: 0 auto;
                margin-bottom: 23px;
                background-color: var(--color-lessdark);
                line-height: 90px;
                align-items: center;
                font-size: 60px;
                font-weight: var(--p-extra-bold);
            }
        }

        &.with-working-process {
            position: relative;

            @media #{$lg-layout} {
                padding: 20px 0;
            }

            .icon {
                width: 180px;
                height: 180px;
                line-height: 180px;
                font-size: 60px;
                position: relative;
            }

            .line {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: 100%;
                left: 100%;
                background-color: var(--color-border);

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }

            &:hover {
                transform: none;
            }
        }
    }


    &.gallery-style {
        padding: 40px 30px;
        background: var(--color-blackest);
        border-radius: 24px;
        box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
        margin-top: 30px;

        .content {
            .image {
                img {
                    border-radius: 5px;
                }
            }

            .title {
                margin-bottom: 19px;
                font-weight: 600;
                font-size: 20px;
                @extend %transition;
                margin-bottom: 10px;

                a {
                    color: inherit;
                    @extend %transition;

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }

            p {
                @extend %transition;
                color: var(--color-body);
                margin-bottom: 20px;
            }
        }
    }


    &.service__style--2 {
        padding: 50px 35px;
        padding-bottom: 55px;
        transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        position: relative;
        z-index: 2;
        border-radius: 10px;
        margin-top: 30px;

        @media #{$laptop-device} {
            padding: 10px 28px;
        }

        @media #{$lg-layout} {
            padding: 10px 15px;
        }

        @media #{$md-layout} {
            padding: 10px 25px;
        }

        @media #{$sm-layout} {
            padding: 0px 18px;
        }

        .image {
            margin-bottom: 23px;

            img {
                max-height: 220px;
            }
        }

        .content {
            .title {
                @extend %transition;

                a {
                    color: inherit;
                    @extend %transition;

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }

            p {
                @extend %transition;
                padding: 0 5%;
            }
        }

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 0 39px rgba(255, 101, 80, 0.1);
        }
    }
}

.rn-service-area .animated {
    height: 100%;
}




.service-wrapper {
    &>[class*=col] {

        .service {
            &.service__style--1 {

                .icon {
                    color: var(--color-pink);
                    width: 96px;
                    height: 96px;

                    & img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .price {
                    color: var(--color-heading);
                    font-weight: var(--p-bold);
                    font-size: var(--font-size-b1);
                    // background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
                    text-align: center;
                    text-transform: uppercase;
                    border-radius: var(--radius-small);
                    padding: 8px 0px;
                    border: 2px solid;
                }


            }

            &.service__style--1.with-working-process {
                align-items: center;
                justify-content: center;

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    & ul {
                        width: max-content;
                        text-align: left;
                        margin-bottom: 0px;
                        padding-left: 0px;

                        & li {
                            font-size: var(--font-size-b2);
                        }
                    }
                }
            }
        }
    }

    &>[class*=col] {

        &:last-child {
            .with-working-process {

                .line {
                    display: none;
                }
            }
        }



    }
}