/*---------------------
    Tab Styles  
-----------------------*/

.rn-default-tab {

    // Tab Button 
    .tab-button {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        .react-tabs__tab {
            padding: 20px 44px;
            margin: 0;

            @media #{$lg-layout} {
                padding: 20px 24px;
            }

            @media #{$md-layout} {
                padding: 20px 24px;
            }

            @media #{$sm-layout} {
                padding: 20px 24px;
            }

            &.react-tabs__tab--selected {
                background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
                border-radius: var(--radius) var(--radius) 0px 0px;
            }
        }

        .rn-tab-button {
            button {
                padding: 0;
                margin: 0;
                background: transparent;
                border: 0 none;
                color: var(--color-white);
            }
        }
    }

    .tab-content-panel {
        border-radius: var(--radius);
        background: var(--color-blackest);
        border: 1px solid var(--color-border);


        & .service-wrapper {
            padding: 32px 0px 48px 0px;
        }
    }

    // Tab Content 
    .rn-tab-content {
        background: var(--color-lessdark);
        padding: 40px 40px;


        @media #{$sm-layout} {
            padding: 30px;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-two {
        .tab-button {
            margin-bottom: 30px;

            .react-tabs__tab {
                border-radius: var(--radius-small);
            }
        }

        .rn-tab-content {
            border-radius: var(--radius-small);
        }
    }
}

.rwt-tab-area {
    @media #{$md-layout} {
        &>div>.mb--40 {
            margin-bottom: 0 !important;
        }
    }

    @media #{$sm-layout} {
        &>div>.mb--40 {
            margin-bottom: 0 !important;
        }
    }
}

.fl-steps {
    display: flex;

    &__headers {
        width: 32%;
        border-right: 1px solid var(--color-border);

        @media #{$lg-layout} {
            width: 27%;
        }
    }

    &__title {
        border-bottom: 1px solid var(--color-border);
        padding: 18px 24px;
    }

    &__body {
        flex: 1;
    }

    &__item {
        padding: 26px 24px;
        border-bottom: 1px solid var(--color-border);
        line-height: 1;

        @media #{$lg-layout} {
            padding: 26px 16px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &__body-top {
        border-bottom: 1px solid var(--color-border);
        display: flex;

        &>div {
            border-right: 1px solid var(--color-border);
            padding: 18px 10px;
            text-align: center;

            @media #{$lg-layout} {
                padding: 18px 21px;
            }

            &:last-child {
                border-right: none;
            }
        }

        &__body-top_8 {
            width: calc(100% / 8);
        }

        &>.en {
            padding: 18px 10px;
        }
    }

    &__body-item {
        padding: 9px 12px;
        border-bottom: 1px solid var(--color-border);


        &:last-child {
            border-bottom: none;
        }

        &>div {
            height: 50px;
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
            border-radius: 32px;
            position: relative;
            display: flex;
            align-items: center;

            &>div:first-child {
                width: 46px;
                height: 46px;
                background-color: #fff;
                border-radius: 100%;
                margin-left: 4px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .3);
                display: flex;
                align-items: center;
                justify-content: center;

                & img {
                    width: 32px;
                    height: 32px;
                    object-fit: contain;
                }
            }
        }

        &.w-1 {
            &>div {
                width: 140px;

                @media #{$slaptop-device} {
                    width: 120px;
                }

                @media #{$lg-layout} {
                    width: 100px;
                }
            }
        }

        &.w-2 {
            &>div {
                width: 310px;

                @media #{$slaptop-device} {
                    width: 265px;
                }

                @media #{$lg-layout} {
                    width: 215px;
                }

                @media #{$xsmall-mobile} {
                    width: 265px;
                }

            }
        }

        &.w-05 {
            &>div {
                width: 80px;
            }
        }

        &.w-0 {
            &>div {
                width: 50px;

                &>div:first-child {
                    margin-left: 5px;
                    width: 40px;
                    height: 40px;

                    & img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

    }

    &_body-1>div {
        left: 140px;

        @media #{$slaptop-device} {
            left: 120px;
        }

        @media #{$lg-layout} {
            left: 105px;
        }
    }

    &_body-2>div {
        left: 280px;

        @media #{$slaptop-device} {
            left: 240px;
        }

        @media #{$lg-layout} {
            left: 215px;
        }
    }

    &_body-3>div {
        left: 351px;
    }

    &_body-4>div {
        left: 585px;

        @media #{$slaptop-device} {
            left: 500px;
        }

        @media #{$lg-layout} {
            left: 430px;
        }
    }

    &_body-45>div {
        left: 660px;

        @media #{$slaptop-device} {
            left: 570px;
        }

        @media #{$lg-layout} {
            left: 475px;
        }
    }

    &_body-5>div {
        left: 585px;
    }

    &_body-6>div {
        left: 795px;

        @media #{$slaptop-device} {
            left: 690px;
        }

        @media #{$lg-layout} {
            left: 572px;
        }
    }


}

.fl-steps__8 {
    .fl-steps__body-top {

        &>div {
            width: calc(100% / 8);
        }
    }

    .fl-steps__item {

        @media #{$slaptop-device} {
            padding: 26px 12px;
        }
    }

    .fl-steps__headers {
        @media #{$slaptop-device} {
            width: 34%;
        }
    }


    .w-1 {
        &>div {
            width: 100px;

            @media #{$slaptop-device} {
                width: 80px;
            }

            @media #{$lg-layout} {
                width: 100px;
            }
        }
    }

    .w-15 {
        &>div {
            width: 165px;

            @media #{$slaptop-device} {
                width: 135px;
            }

            @media #{$lg-layout} {
                width: 220px;
            }

            @media #{$md-layout} {
                width: 220px;
            }

            @media #{$sm-layout} {
                width: 220px;
            }

            @media #{$xsmall-mobile} {
                width: 265px;
            }
        }
    }

    .w-2 {
        &>div {
            width: 220px;

            @media #{$slaptop-device} {
                width: 265px;
            }

            @media #{$lg-layout} {
                width: 215px;
            }

            @media #{$xsmall-mobile} {
                width: 265px;
            }

        }
    }

    .w-4 {
        &>div {
            width: 450px;

            @media #{$slaptop-device} {
                width: 365px;
            }

            @media #{$lg-layout} {
                width: 600px;
            }

            @media #{$md-layout} {
                width: 600px;
            }

            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }

    .w-05 {
        &>div {
            width: 70px;
        }
    }

    .w-0 {
        &>div {
            width: 50px;

            &>div:first-child {
                margin-left: 5px;
                width: 40px;
                height: 40px;

                & img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .fl-steps_body-1>div {
        left: 100px;

        @media #{$slaptop-device} {
            left: 120px;
        }

        @media #{$lg-layout} {
            left: 105px;
        }
    }

    .fl-steps_body-05>div {
        left: 45px;

        @media #{$slaptop-device} {
            left: 30px;
        }

        @media #{$lg-layout} {
            left: 105px;
        }
    }

    .fl-steps_body-2>div {
        left: 208px;

        @media #{$slaptop-device} {
            left: 170px;
        }

        @media #{$lg-layout} {
            left: 215px;
        }
    }

    .fl-steps_body-3>div {
        left: 310px;

        @media #{$slaptop-device} {
            left: 265px;
        }
    }

    .fl-steps_body-4>div {
        left: 420px;

        @media #{$slaptop-device} {
            left: 355px;
        }

        @media #{$lg-layout} {
            left: 430px;
        }
    }

    .fl-steps_body-45>div {
        left: 480px;

        @media #{$slaptop-device} {
            left: 570px;
        }

        @media #{$lg-layout} {
            left: 475px;
        }
    }

    .fl-steps_body-5>div {
        left: 545px;

        @media #{$slaptop-device} {
            left: 455px;
        }
    }

    .fl-steps_body-6>div {
        left: 660px;

        @media #{$slaptop-device} {
            left: 690px;
        }

        @media #{$lg-layout} {
            left: 572px;
        }
    }


}

.fl-steps__6 {
    .fl-steps__body-top {

        &>div {
            width: calc(100% / 6);
        }
    }
}



.fl-steps-mobile {
    padding: 32px;

    &__block {
        margin-bottom: 28px;
    }

    &__task {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
    }

    & .fl-steps__body-item {
        padding: 12px 0px 0px 0px;
    }

    & .fl-steps__body-item>div>div:first-child {
        margin-right: 10px;
    }

    & .fl-steps__body-item.w-1>div {
        width: 150px;
    }

    .fl-steps__body-item.w-05>div {
        width: 130px;
    }

    & .fl-steps__body-item>div>div:last-child {
        color: #fff;
        font-size: 14px;
    }

    & .fl-steps__body-item.w-0>div {
        width: 115px;
    }
}