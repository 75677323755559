/*--------------------------
    Header Styles  
 --------------------------*/
.logo-dark {
    display: none;
}

.header-default {
    max-width: 100%;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-darker);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;

    .logo {
        a {
            display: flex;
            height: 80px;
            line-height: 80px;
            align-items: center;

            img {
                max-height: 45px;
            }
        }
    }

    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.header-relative {
        position: relative;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;

        .header-btn {
            margin-left: 25px;

            @media #{$lg-layout} {
                margin-left: 10px;
            }

            @media #{$md-layout} {
                margin-left: 10px;
            }

            @media #{$sm-layout} {
                margin-left: 10px;
            }


            @media #{$large-mobile} {
                display: none;
            }

        }
    }

    a,
    button {
        &.btn-default {
            @media #{$sm-layout} {
                height: 33px;
                line-height: 30px;
                padding: 0 9px;
                font-size: 12px;
            }

            &.btn-small {
                @media #{$sm-layout} {
                    height: 33px;
                    line-height: 30px;
                    padding: 0 9px;
                    font-size: 12px;
                }
            }
        }
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background: transparent !important;
        backdrop-filter: inherit;
    }
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media #{$md-layout} {
        position: relative;
    }

    @media #{$sm-layout} {
        position: relative;
    }

    .header-top-bar {
        background-color: transparent;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }

    .header-default {
        background-color: transparent;
        background-image: none;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }
}



.hamberger-button {
    background: var(--color-blackest);
    border: 0 none;
    color: var(--color-white);
    width: 40px;
    height: 40px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;

    svg {
        position: relative;
        z-index: 2;
        stroke-width: 2px;
        stroke: #fff;
    }
}

.header-left-align {
    .mainmenu-nav {
        margin-left: 30px;
    }
}



.rn-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-dark) !important;
    width: 100%;
    height: 80px;
    animation: headerSticky .95s ease forwards;
    z-index: 9;
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.language {
    display: flex;
    padding: 4px 8px 4px 6px;
    align-items: center;
    border: none;
    border-radius: 16px;
    color: #fff;
    background-color: #1e1e1e;

    @media #{$lg-layout} {
        padding: 4px 6px 4px 6px;
    }
}

.language.active {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

.language>div:first-child {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 5px;

    @media #{$lg-layout} {
        margin-right: 0px;
    }
}

.language_ru {
    margin-right: 8px;
    margin-left: 24px;

    @media #{$lg-layout} {
        margin-left: 12px;
        margin-right: 6px;
    }
}

.language>div:last-child {
    font-weight: 700;

    @media #{$lg-layout} {
        display: none;
    }
}