/**
 * Typography
 */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
	box-sizing: border-box;
}

html {
	overflow: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
	font-size: 10px;
}

body {
	overflow: hidden;
	font-size: var(--font-size-b2);
	;
	line-height: var(--line-height-b2);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-primary);
	color: var(--color-body);
	font-weight: var(--p-regular);
	background-color: var(--color-dark);
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
	margin: 0;
	margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	word-break: break-word;
	font-family: var(--secondary-font);
	line-height: 1.4074;
	color: var(--color-heading);
}

h1,
.h1 {
	font-size: var(--h1);
	line-height: 1.19;
}

h2,
.h2 {
	font-size: var(--h2);
	line-height: 1.23;
}

h3,
.h3 {
	font-size: var(--h3);
	line-height: 1.4;
}

h4,
.h4 {
	font-size: var(--h4);
	line-height: 1.25;
}

h5,
.h5 {
	font-size: var(--h5);
	line-height: 1.24;
}

h6,
.h6 {
	font-size: var(--h6);
	line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	a {
		color: inherit;
		transition: 0.5s;
	}
}

a {
	color: var(--color-body);
}

@media #{$md-layout} {

	h1,
	.h1 {
		font-size: 38px;
	}

	h2,
	.h2 {
		font-size: 32px;
	}

	h3,
	.h3 {
		font-size: 28px;
	}

	h4,
	.h4 {
		font-size: 24px;
	}

	h5,
	.h5 {
		font-size: 18px;
	}
}


@media #{$sm-layout} {

	h1,
	.h1 {
		font-size: 34px;
	}

	h2,
	.h2 {
		font-size: 28px;
	}

	h3,
	.h3 {
		font-size: 24px;
	}

	h4,
	.h4 {
		font-size: 20px;
	}

	h5,
	.h5 {
		font-size: 16px;
	}

}


h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5 {
	font-weight: var(--p-bold);
}

h6,
.h6 {
	font-weight: var(--p-medium);
}

p {
	font-size: var(--font-size-b2);
	;
	line-height: var(--line-height-b2);
	font-weight: var(--p-regular);
	color: var(--color-body);
	margin: 0 0 30px;

	@media #{$sm-layout} {
		margin: 0 0 20px;
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.has-large-font-size {
		line-height: 1.5;
		font-size: 36px;
	}

	&.has-medium-font-size {
		font-size: 24px;
		line-height: 36px;
	}

	&.has-small-font-size {
		font-size: 13px;
	}

	&.has-very-light-gray-color {
		color: var(--color-white);
	}

	&.has-background {
		padding: 20px 30px;
	}

	&.b1 {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);

		@media #{$large-mobile} {
			font-size: var(--font-size-b2);
		}
	}

	&.b2 {
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.b3 {
		font-size: var(--font-size-b3);
		line-height: var(--line-height-b3);
	}

	&.b4 {
		font-size: var(--font-size-b4);
		line-height: var(--line-height-b4);
	}


}

.b1 {
	font-size: var(--font-size-b1);
	line-height: var(--line-height-b1);
}

.b2 {
	font-size: var(--font-size-b2);
	line-height: var(--line-height-b2);
}

.b3 {
	font-size: var(--font-size-b3);
	line-height: var(--line-height-b3);
}

.b4 {
	font-size: var(--font-size-b4);
	line-height: var(--line-height-b4);
}


table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 0 40px;
	width: 100%;
}

table a,
table a:link,
table a:visited {
	text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
	color: var(--color-heading);
}


var {
	font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
	padding-left: 18px;
}

ul {
	list-style: square;
	margin-bottom: 30px;
	padding-left: 20px;

	&.liststyle {
		&.bullet {
			li {
				font-size: 18px;
				line-height: 30px;
				color: var(--color-body);
				position: relative;
				padding-left: 30px;

				@media #{$sm-layout} {
					padding-left: 19px;
				}

				&::before {
					position: absolute;
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background: var(--color-body);
					left: 0;
					top: 10px;
				}

				&+li {
					margin-top: 8px;
				}
			}
		}
	}

	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		margin-top: 10px;
		margin-bottom: 10px;
		color: var(--color-body);
		font-weight: 400;

		a {
			text-decoration: none;
			color: var(--color-body);
			@extend %transition;

			&:hover {
				color: var(--color-primary);
			}
		}
	}

	ul {
		margin-bottom: 0;
	}
}

ol {
	margin-bottom: 30px;

	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		color: var(--color-gray);
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: 400;

		a {
			color: var(--heading-color);
			@extend %transition;
			text-decoration: none;

			&:hover {
				color: var(--color-primary);
			}
		}
	}

	ul {
		padding-left: 30px;
	}
}

hr {
	display: block;
	height: 0;
	margin: 15px 0 15px;
	border-top: 1px solid var(--color-border);
}

kbd {
	background: var(--color-primary);
}

// scrollbar
body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: transparent;
}

body::-webkit-scrollbar {
	width: 10px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

// Кастомные radio

.fl-radio {
	margin: 28px 0px;

	&_button {
		display: inline-block;
		margin-right: 20px;

		@media #{$xsmall-mobile} {
			margin-right: 10px;
		}

		& input[type=radio] {
			display: none;

			&:checked+label {
				background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
				color: #fff;
				border: none;
				padding: 11px 24px;
			}

			&:not(:checked)+label:hover {
				border: 2px solid #666;
				color: #666 !important;
				transition: all .2s ease-in;
			}

			&:checked+label:hover {
				color: #fff;
			}

			&:disabled+label {
				background: #efefef;
				color: #666;
			}

			&~label::before,
			&~label::after {
				content: none;
			}
		}

		& label {
			display: inline-block;
			cursor: pointer;
			padding: 8px 24px;
			line-height: 34px;
			border: 2px solid #fff;
			border-radius: 6px;
			user-select: none;
			color: #fff !important;
		}


	}

}

// Кастомный input file
.input-file {
	position: relative;
	display: inline-block;
	border: var(--border-width) solid var(--color-border);
	padding: 17px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--radius);
	flex-direction: column;
	cursor: pointer;
}

.input-file-btn {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 14px;
	vertical-align: middle;
	color: rgb(255 255 255);
	text-align: center;
	line-height: 1.2;
	box-sizing: border-box;
	transition: background-color 0.2s;
	padding-bottom: 12px;
	font-weight: bold;
}

.input-file-text {
	padding: 0 10px;
	line-height: 1;
	display: inline-block;
	font-size: 14px;
}

.input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}

/* Focus */
.input-file input[type=file]:focus+.input-file-btn {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Disabled */
.input-file input[type=file]:disabled+.input-file-btn {
	background-color: #eee;
}





.requisites-area {
	height: calc(100vh - 216px);
	display: flex;
	align-items: center;

	@media #{$md-layout} {
		height: 100%;
	}

	@media #{$sm-layout} {
		height: 100%;
	}
}

.requisites {
	&__block {
		padding: 40px 30px;
		height: 100%;
		border: 1px solid var(--color-border);

		& h3 {
			font-size: 40px;
			background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: 800;
			line-height: 1;
			margin-bottom: 34px;
		}

		& p {
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0px;
			}
		}

	}

	&_kazakhstan {
		background-image: url('../../images/icons/kazakhstan.png');
		background-size: 360px;
		background-repeat: no-repeat;
		background-position-x: 145%;
		background-position-y: center;

		@media #{$slaptop-device} {
			background-position-x: 200%;
		}

		@media #{$lg-layout} {
			background-position-x: 380%;
		}

		@media #{$md-layout} {
			background-position-x: 120%;
		}

		@media #{$sm-layout} {
			background-position-x: 222%;
		}

		@media #{$large-mobile} {
			background-image: none;
		}

	}

	&_russia {
		background-image: url('../../images/icons/russia.png');
		background-size: 360px;
		background-repeat: no-repeat;
		background-position-x: 130%;
		background-position-y: center;

		@media #{$slaptop-device} {
			background-position-x: 150%;
		}

		@media #{$lg-layout} {
			background-position-x: 280%;
		}

		@media #{$md-layout} {
			background-position-x: 122%;
		}

		@media #{$sm-layout} {
			background-position-x: 166%;
		}

		@media #{$large-mobile} {
			background-image: none;
		}

	}
}